/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import {
    useLocation,
    //  Link,
    useHistory,
} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/toolkit/SuiBox";
import SuiTypography from "components/toolkit/SuiTypography";
// import SuiInput from "components/toolkit/SuiInput";

// Soft UI Dashboard React example components
import Breadcrumbs from "components/toolkit/Breadcrumbs";
import NotificationItem from "components/toolkit/NotificationItem";

// Custom styles for DashboardNavbar
import styles from "components/toolkit/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { MenuItem, Box } from "components";
import { Fade } from "@mui/material";
import ModalLayout from "components/layouts/ModalLayout";
import { useSelector, useDispatch } from "react-redux";
import { getAccountDetails } from "redux/db-user/DBuserActions";
import { Avatar } from "@mui/material";
import { selectProfile } from "selectors/profile/profileSelector";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "components/toolkit";
import AppTitle from "./AppTitle";
import CreateNewButton from "./CreateNewButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CopyContentComponent from "components/frequently-used-components/tool-tip-copy-component/CopyContentComponent";

function DashboardNavbar({ absolute, light, isMini }) {
    const [navbarType, setNavbarType] = useState();
    const sentinelRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const state = useSelector((state) => state.user.currentUser);
    const account = useSelector((state) => state.dbUser.account_details);
    const profile = useSelector(selectProfile(account?.id));
    const [controller, uiControllerDispatch] = useSoftUIController();
    const dispatch = useDispatch();
    const accountDetails = useSelector((state) => state.dbUser.account_details);
    const { miniSidenav, transparentNavbar, fixedNavbar, appTitle } =
        controller;
    const [openMenu, setOpenMenu] = useState(false);
    const classes = styles({ transparentNavbar, absolute, light, isMini });
    const route = useLocation().pathname.split("/").slice(1);
    const history = useHistory();
    const [logout, setLogout] = useState(null);
    const openLogout = Boolean(logout);
    const basePath = route[0];
    const handleLogout = (event) => {
        setLogout(event.currentTarget);
    };
    const handleCloseLogout = () => {
        setLogout(null);
    };

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }
        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar(event) {
            const isTransparent =
                (fixedNavbar && window.scrollY === 0) || !fixedNavbar;
            uiControllerDispatch({
                type: "TRANSPARENT_NAVBAR",
                value: isTransparent,
            });
        }

        window.addEventListener("scroll", handleTransparentNavbar);
        // Todo: fetching logged in user profile should happen in AuthRoutes
        dispatch(getAccountDetails(state.account_id));

        // // Remove event listener on cleanup
        return () => {
            window.removeEventListener("scroll", handleTransparentNavbar);
        };
    }, [dispatch, fixedNavbar, uiControllerDispatch, state.account_id]);

    const handleMiniSidenav = () =>
        uiControllerDispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
    // const handleConfiguratorOpen = () =>
    //   uiControllerDispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });

    // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            style={{ marginTop: "1rem" }}>
            <NotificationItem
                image={<img src={team2} alt='person' />}
                title={["New message", "from Laur"]}
                date='13 minutes ago'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt='person' />}
                title={["New album", "by Travis Scott"]}
                date='1 day'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color='secondary'
                image={
                    <Icon
                        fontSize='small'
                        className='material-icon-round text-white'>
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date='2 days'
                onClick={handleCloseMenu}
            />
        </Menu>
    );

    const handleLogoutOnline = () => {
        // todo: call backend API to logout the current user
        localStorage.clear();
        window.location.href = "/admin/login";
    };

    const handleLogoutClick = () => {
        setShowModal(true);
        handleCloseLogout();
    };
    const toggle = () => {
        setShowModal(false);
    };

    const handleProfileClick = () => {
        handleCloseLogout();
        history.push("/profilePage");
    };

    const checkISAlphaNumericString = () => {
        const regEx = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
        const isStringAlphaNumeric = regEx.test(route[route.length - 1]);
        return isStringAlphaNumeric ? "" : route[route.length - 1];
    };

    return (
        <AppBar
            ref={sentinelRef}
            position={absolute ? "absolute" : navbarType}
            color='inherit'
            sx={{
                // added filter in sx since in mui  v5 there is a bug in makeStyles
                backdropFilter: ({ transparentNavbar, absolute }) =>
                    transparentNavbar || absolute
                        ? "none"
                        : `saturate(200%) blur(30px)`,
            }}
            className={classes.navbar}>
            <ModalLayout
                showModal={showModal}
                handleClick={handleLogoutOnline}
                toggle={toggle}
            />
            <Toolbar className={classes.navbar_container}>
                <SuiBox
                    customClass={classes.navbar_row}
                    color='inherit'
                    flex={1}
                    mb={{ xs: 1, md: 0 }}>
                    <IconButton
                        size='small'
                        color='inherit'
                        className={classes.navbar_mobile_menu}
                        onClick={handleMiniSidenav}>
                        <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
                    </IconButton>

                    {!route.includes("view-user") && (
                        <AppTitle appTitle={appTitle} route={route} />
                    )}
                </SuiBox>
                {basePath === "projects" && (
                    <Box flex={1}>
                        <CreateNewButton />
                    </Box>
                )}
                {isMini ? null : (
                    <SuiBox
                        gap={1}
                        mr={2}
                        display='flex'
                        alignItems='center'
                        justifyContent='flex-end'
                        customClass={classes.navbar_row_bottom}>
                        <SuiBox pr={1}></SuiBox>
                        <SuiBox
                            color={light ? "white" : "inherit"}
                            customClass={classes.navbar_section_desktop}>
                            {renderMenu()}
                        </SuiBox>
                        {accountDetails?.tymelineId && (
                            <Typography
                                variant='label1'
                                textColor='light'
                                fontWeight='bold'>
                                {accountDetails.tymelineId}
                                <CopyContentComponent
                                    content={accountDetails.tymelineId}
                                    msg='Click to copy your tymeline id'
                                    color='light'
                                    styleProps={{
                                        display: "inline",
                                        ml: 0.5,
                                    }}
                                />
                            </Typography>
                        )}
                        {/* {route.includes("user-dashboard") && (
                            <Tooltip title='The information presented on this page will be exclusively visible to you.'>
                                <Box className='cursor-pointer'>
                                    <InfoIcon />
                                </Box>
                            </Tooltip>
                        )} */}

                        <IconButton
                            className={classes.navbar_icon_button}
                            aria-controls='fade-menu'
                            aria-haspopup='true'
                            aria-expanded={openLogout ? "true" : undefined}
                            onClick={handleLogout}>
                            <Box display='flex' alignItems='center' gap={1}>
                                <Avatar
                                    sx={{ width: 36, height: 36 }}
                                    src={accountDetails?.profilePhoto?.url}>
                                    {profile?.firstName.substr(0, 1)}
                                </Avatar>
                                <Box
                                    display='flex'
                                    gap={0}
                                    flexDirection='column'
                                    alignItems='flex-start'
                                    justifyContent='center'>
                                    <Typography
                                        variant='subtitle3'
                                        fontWeight='bold'
                                        textColor='2dark'>
                                        {accountDetails?.firstName +
                                            " " +
                                            accountDetails?.lastName ?? ""}
                                    </Typography>
                                    <Typography
                                        variant='label2'
                                        textColor='text'>
                                        {accountDetails?.is_superuser
                                            ? "Admin"
                                            : "Team Member"}
                                    </Typography>
                                </Box>
                                <KeyboardArrowDown
                                    fontSize='small'
                                    color='light'
                                />
                            </Box>
                        </IconButton>
                        <Menu
                            id='fade-menu'
                            MenuListProps={{
                                "aria-labelledby": "fade-button",
                            }}
                            anchorEl={logout}
                            open={openLogout}
                            onClose={handleCloseLogout}
                            TransitionComponent={Fade}>
                            <MenuItem onClick={handleProfileClick}>
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogoutClick}>
                                Logout
                            </MenuItem>
                        </Menu>
                    </SuiBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
