import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { Sidenav } from "components/toolkit";
import authRoutesConfig from "./authRoutesConfig";
import { userSelector } from "selectors/user";
import { sideNavSelector } from "selectors/labelSelectors";
import "./AuthRoutes.scss";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import SuiTypography from "components/toolkit/SuiTypography";
import AppNavigation from "AppNavigation";
import ErrorDisplaySection from "ErrorDisplaySection";
import { Spinner } from "components/toolkit";
import NotAuthorizedPage from "./NotAuthorizedPage";
import { SurveySection, Box } from "components/toolkit";
import { useSoftUIController, setOpenConfigurator } from "context";
// import { surveyStatusSelector } from "selectors/userDahboard";
import { surveysListSelector } from "selectors/userDahboard";
import logger, { setUser as loggerUser } from "../datadog/logger";
import { setUser as rumUser, startRecording } from "../datadog/monitoring";
import { selectLoggedInUserDetails } from "selectors/user/loggedInUser";

const AuthRoutes = () => {
    const [controller, dispatch] = useSoftUIController();
    const { openConfigurator, miniSidenav, pinSidenav } = controller;
    const light = false;
    const currentUser = useSelector(userSelector);
    const loggedInUserDetails = useSelector(selectLoggedInUserDetails);
    const menuRoutes = authRoutesConfig.filter((config) => config.showInMenu);
    const hideSideNav = useSelector(sideNavSelector);
    const showSurveyButton = false; //useSelector(surveyStatusSelector);
    const surveysList = useSelector(surveysListSelector);
    // Revisit: This check is pretty week.s
    const isUserLoggedIn = currentUser !== null;
    // const accessPermissions = currentUser?.access?.concat("default");
    const location = useLocation();
    const { pathname } = location;
    const urlKey = pathname.split("/").slice(1)[0];
    // const [onMouseEnter, setOnMouseEnter] = useState(false);
    const rightClickDisable = (e) => {
        if (
            window.location.pathname === "/admin/dashboard" ||
            window.location.pathname === "/admin/create-workflow"
        ) {
            return e.preventDefault();
        } else {
            return;
        }
    };

    // const handleOnMouseEnter = () => {
    //     if (miniSidenav && !onMouseEnter) {
    //         dispatch({ type: "MINI_SIDENAV", value: false });
    //         setOnMouseEnter(true);
    //     }
    // };

    // Close sidenav when mouse leave mini sidenav
    // const handleOnMouseLeave = () => {
    //     if (onMouseEnter) {
    //         setOnMouseEnter(false);

    //         if (!pinSidenav) {
    //             dispatch({ type: "MINI_SIDENAV", value: true });
    //         }
    //     }
    // };

    const handleConfiguratorOpen = () =>
        setOpenConfigurator(dispatch, !openConfigurator);

    const getRoutes = (collapses) => {
        return collapses?.map((collapse, index) => {
            if (collapse?.collapse) {
                return getRoutes(collapse?.collapse);
            } else {
                const role = collapse.role ?? "someValue";
                // const hasAccess = ["verificationHub", "default"].includes(role);
                const hasAccess = role.includes(
                    currentUser?.dashboardRoleType?.[0]
                );
                //  const hasAccess = collapse.accessCondition({user});
                // console.log(accessPermissions);
                if (hasAccess) {
                    return (
                        <Route
                            component={collapse?.component}
                            path={collapse?.path}
                            key={index + 4}
                            exact={collapse.exact}></Route>
                    );
                } else {
                    <Redirect to='/login' />;
                }
                return (
                    <React.Fragment key={index + 5 + collapse?.path}>
                        {/* <p>sdciodcsbio</p> */}
                        {/* {index === 0 && <NotAuthorizedPage />} */}
                    </React.Fragment>
                );
            }
        });
    };

    useEffect(() => {
        if (loggedInUserDetails?.id) {
            rumUser(loggedInUserDetails);
            loggerUser(loggedInUserDetails);
            logger.info("Test user - logged in user", {
                userDetails: loggedInUserDetails,
            });
            startRecording();
        }
    }, [loggedInUserDetails]);

    return isUserLoggedIn ? (
        <div onContextMenu={(e) => rightClickDisable(e)}>
            <section className='auth-section'>
                {!hideSideNav &&
                    urlKey !== "reset-password" &&
                    urlKey !== "acceptteaminvite" &&
                    urlKey !== "acceptuserinvite" &&
                    urlKey !== "create-new-user" &&
                    urlKey !== "master" &&
                    urlKey !== "self-initiate" &&
                    urlKey !== "login" &&
                    urlKey !== "signup" &&
                    urlKey !== "" && (
                        <Sidenav
                            routes={menuRoutes}
                            // onMouseEnter={handleOnMouseEnter}
                            // onMouseLeave={handleOnMouseLeave}
                        />
                    )}
                <SurveySection surveysList={surveysList} />
                <Box>
                    {showSurveyButton && (
                        <button
                            className='survey-button'
                            onClick={handleConfiguratorOpen}>
                            Take survey
                        </button>
                    )}
                </Box>
                <DashboardLayout
                    showHeader={
                        !hideSideNav &&
                        urlKey !== "reset-password" &&
                        urlKey !== "acceptuserinvite" &&
                        urlKey !== "create-new-user" &&
                        urlKey !== "acceptteaminvite" &&
                        urlKey !== "master" &&
                        urlKey !== "self-initiate" &&
                        urlKey !== "login" &&
                        urlKey !== "signup" &&
                        urlKey !== ""
                    }>
                    <AppNavigation />
                    <ErrorDisplaySection />
                    <main>
                        <Switch>
                            <React.Suspense
                                fallback={
                                    <Box m={3}>
                                        <SuiTypography variant='label1'>
                                            {
                                                "Hang on while we are getting your data..."
                                            }
                                        </SuiTypography>
                                    </Box>
                                }>
                                {authRoutesConfig
                                    ?.filter(({ type }) => type === "collapse")
                                    ?.map((routeConfig, index) => {
                                        if (routeConfig.collapse) {
                                            return getRoutes(
                                                routeConfig?.collapse,
                                                index
                                            );
                                        } else {
                                            return (
                                                <Route
                                                    menuRoutes={menuRoutes}
                                                    {...routeConfig}
                                                    exact={true}
                                                    key={index}
                                                />
                                            );
                                        }
                                    })}
                            </React.Suspense>
                        </Switch>
                    </main>
                </DashboardLayout>
            </section>
        </div>
    ) : urlKey === "acceptuserinvite" || urlKey === "create-new-user" ? (
        <section className='auth-section'>
            <AppNavigation />
            <ErrorDisplaySection />
            <main>
                <Switch>
                    <React.Suspense
                        fallback={
                            <Box m={3}>
                                <SuiTypography variant='label1'>
                                    {
                                        "Hang on while we are getting your data..."
                                    }
                                </SuiTypography>
                            </Box>
                        }>
                        {authRoutesConfig
                            ?.filter(({ type }) => type === "collapse")
                            ?.map((routeConfig, index) => {
                                if (routeConfig.collapse) {
                                    return getRoutes(
                                        routeConfig?.collapse,
                                        index
                                    );
                                } else {
                                    return (
                                        <Route
                                            menuRoutes={menuRoutes}
                                            {...routeConfig}
                                            key={index}
                                        />
                                    );
                                }
                            })}
                        <Route path='*'>
                            <NotAuthorizedPage />
                        </Route>
                    </React.Suspense>
                </Switch>
            </main>
        </section>
    ) : urlKey === "login" ||
      urlKey === "signup" ||
      urlKey === "generate-master-key" ||
      urlKey === "tymeline" ||
      urlKey === "acceptuserinvite" ||
      urlKey === "" ||
      urlKey === "master" ||
      urlKey === "self-initiate" ||
      urlKey === "tymeline-login" ? (
        <></>
    ) : (
        <Redirect to='/login' />
    );
};

export default AuthRoutes;
