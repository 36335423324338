/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(
    ({
        palette,
        transitions,
        breakpoints,
        typography,
        boxShadows,
        borders,
        functions,
    }) => {
        const { white, info, text, gradients, light, transparent, grey } =
            palette;
        const { fontWeightRegular, fontWeightMedium, fontWeightBold, size } =
            typography;
        const { xxl } = boxShadows;
        const { borderRadius, borderWidth } = borders;
        const { pxToRem, rgba } = functions;

        return {
            collapse_item: {
                background: ({ active }) =>
                    active
                        ? "linear-gradient(91.51deg, #FF5733 0%, #FA9985 102.39%)"
                        : transparent.main,
                color: ({ active }) => (active ? white.main : text.main),
                display: "flex",
                alignItems: "center",
                width: "100%",
                padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(
                    10.8
                )} ${pxToRem(16)}`,
                margin: `0 ${pxToRem(8)}`,
                borderRadius: borderRadius.section,
                cursor: "pointer",
                userSelect: "none",
                whiteSpace: "nowrap",
                boxShadow: "none",
                [breakpoints.up("xl")]: {
                    boxShadow: ({ active, transparentSidenav }) => {
                        if (active) {
                            return transparentSidenav ? xxl : "none";
                        }

                        return "none";
                    },
                    transition: transitions.create("box-shadow", {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter,
                    }),
                },
            },

            collapse_iconBox: {
                background: ({ active, sidenavColor }) => {
                    if (active) {
                        return sidenavColor === "default"
                            ? info.main
                            : transparent.main;
                    }

                    return transparent.main;
                },
                minWidth: pxToRem(32),
                minHeight: pxToRem(32),
                borderRadius: borderRadius.md,
                display: "grid",
                placeItems: "center",
                transition: transitions.create("margin", {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.standard,
                }),

                [breakpoints.up("xl")]: {
                    background: ({
                        active,
                        transparentSidenav,
                        sidenavColor,
                    }) => {
                        let background;

                        if (!active) {
                            background = transparentSidenav
                                ? transparent.main
                                : light.main;
                        } else if (sidenavColor === "default") {
                            background = info.main;
                        } else if (sidenavColor === "warning") {
                            background = gradients.warning.main;
                        } else {
                            background = transparent.main;
                        }

                        return background;
                    },
                },

                "& svg, svg g": {
                    height: pxToRem(22),
                    width: pxToRem(22),
                    fill: ({ active }) => (active ? white.main : grey[600]),
                    // stroke: ({ active }) => active ? white.main : grey[600],
                    // strokeWidth: borderWidth[2]
                },
            },

            collapse_icon: {
                color: ({ active }) =>
                    active ? white.main : gradients.dark.state,
            },

            collapse_text: {
                marginLeft: pxToRem(12.8),

                [breakpoints.up("xl")]: {
                    opacity: ({ miniSidenav, transparentSidenav }) =>
                        miniSidenav || (miniSidenav && transparentSidenav)
                            ? 0
                            : 1,
                    maxWidth: ({ miniSidenav, transparentSidenav }) =>
                        miniSidenav || (miniSidenav && transparentSidenav)
                            ? 0
                            : "100%",
                    marginLeft: ({ miniSidenav, transparentSidenav }) =>
                        miniSidenav || (miniSidenav && transparentSidenav)
                            ? 0
                            : pxToRem(12.8),
                    transition: transitions.create(["opacity", "margin"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },

                "& span": {
                    fontWeight: ({ active }) =>
                        active ? fontWeightMedium : fontWeightMedium,
                    fontSize: size.sm,
                    lineHeight: 0,
                    display: ({ miniSidenav }) =>
                        miniSidenav ? "none" : "inherit",
                },
            },
            collapse_arrow: {
                fontSize: `${size.md} !important`,
                fontWeight: 700,
                marginBottom: pxToRem(-1),
                transform: ({ open }) =>
                    open ? "rotate(0)" : "rotate(-180deg)",
                color: ({ active }) =>
                    active ? white.main : rgba(gradients.dark.state, 0.4),
                transition: transitions.create(
                    ["color", "transform", "opacity"],
                    {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter,
                    }
                ),

                [breakpoints.up("xl")]: {
                    display: ({
                        noCollapse,
                        transparentSidenav,
                        miniSidenav,
                    }) =>
                        noCollapse ||
                        (transparentSidenav && miniSidenav) ||
                        miniSidenav
                            ? "none !important"
                            : "block !important",
                },
            },
        };
    }
);
