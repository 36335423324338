import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Button } from "components/toolkit";
import { ExpandMore } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const options = [
    { label: "Project", route: "/projects/create-project/" },
    { label: "Epic", route: "/projects/epic/new/" },
    { label: "Story", route: "/projects/create-new-team-goal" },
    // { label: "Milestone", route: "/projects/create-team-member-goal" },
];

export const CreateNewButton = () => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClick = () => {};

    const handleMenuItemClick = (event, index) => {
        // setSelectedIndex(index);
        history.push(options[index].route);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup ref={anchorRef} onClick={handleToggle}>
                <Button
                    size='small'
                    buttonColor='primary'
                    startIcon={<AddIcon />}
                    sx={{ px: 2, py: 1 }}>
                    Create New
                </Button>
                <Button
                    size='small'
                    buttonColor='primary'
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup='menu'
                    sx={{ px: 0.5, py: 0 }}>
                    <ExpandMore />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='split-button-menu'>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            onClick={(event) =>
                                                handleMenuItemClick(
                                                    event,
                                                    index
                                                )
                                            }
                                            sx={{ borderRadius: 0 }}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default CreateNewButton;
