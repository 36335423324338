import React, { useEffect } from "react";
import Footer from "./Footer";
import { DashboardNavbar, Card } from "components/toolkit";
import { default as ToolkitDashboardLayout } from "components/toolkit/LayoutContainers/DashboardLayout";
import { useDispatch } from "react-redux";
import { setSideNavStatus } from "redux/rookie/workflow/WorkflowDetailActions";
import { Box } from "components/toolkit";

export const DashboardLayout = ({
    loadSideNav,
    children,
    showHeader = true,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (loadSideNav) {
            dispatch(setSideNavStatus(false));
        }
    }, [loadSideNav, dispatch]);
    return (
        <ToolkitDashboardLayout showNav={showHeader}>
            {showHeader && <DashboardNavbar />}
            <Box
                flex={1}
                sx={{
                    p: 0,
                    background: "#F1F1F1",
                }}>
                {children}
            </Box>
            <Footer />
        </ToolkitDashboardLayout>
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children, sxProps = {} }) => (
    <Box
        sx={{
            p: 3,
            ...sxProps,
        }}>
        {children}
    </Box>
);
