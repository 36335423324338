/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(
    ({
        palette,
        typography,
        boxShadows,
        transitions,
        breakpoints,
        functions,
    }) => {
        const sidebarWidth = 250;
        const { white, transparent } = palette;
        const { fontWeightMedium } = typography;
        const { xxl } = boxShadows;
        const { pxToRem } = functions;

        return {
            sidenav: {
                boxShadow: xxl,
                border: "none",
                height: "100vh",
                [breakpoints.up("xl")]: {
                    backgroundColor: ({ transparentSidenav }) =>
                        transparentSidenav ? transparent.main : white.main,
                    boxShadow: ({ transparentSidenav }) =>
                        transparentSidenav ? "none" : xxl,
                    margin: ({ transparentSidenav }) =>
                        transparentSidenav ? 0 : "inherit",
                    left: "0",
                },
                borderRadius: "0 0 1rem 0",
            },

            sidenav_divider: {
                padding: 2,
                margin: `0 1rem 0.5rem 1rem`,
                background: `linear-gradient(to right, ${palette.gradients.secondary.main}, ${palette.gradients.secondary.state})`,
            },

            sidenav_header: {
                display: "flex",
                alignItems: "center",
                justifyContent: ({ miniSidenav }) =>
                    miniSidenav ? "center" : "space-between",
                margin: `${pxToRem(8)} ${pxToRem(24)}`,
                textAlign: "center",

                "& a": {
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                },
            },

            sidenav_pin: {
                cursor: "pointer",
            },

            sidenav_logo: {
                width: pxToRem(32),
                // marginRight: pxToRem(8),
            },

            sidenav_logoLabel: {
                minHeight: pxToRem(44),
                maxWidth: pxToRem(166),
                display: "flex",
                alignItems: "center",

                [breakpoints.up("xl")]: {
                    opacity: ({ miniSidenav, transparentSidenav }) =>
                        miniSidenav || (miniSidenav && transparentSidenav)
                            ? 0
                            : 1,
                    transition: transitions.create(["opacity", "margin"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            },

            sidenav_companyName: {
                flex: 1,
                padding: "0 0.5rem",
                fontWeight: "600",
                display: ({ miniSidenav }) => miniSidenav && "none",
                wordWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "100%",
                maxHeight: "44px",
                lineHeight: 1.2,
            },

            sidenav_title: {
                display: "block",
                opacity: 0.6,
                paddingLeft: pxToRem(24),
                margin: `${pxToRem(16)} 0 ${pxToRem(8)} ${pxToRem(8)}`,
            },

            marginTopNone: {
                marginTop: 0,
            },

            sidenav_footer: {
                margin: `${pxToRem(16)}`,
                paddingTop: pxToRem(16),
            },

            sidenav_open: {
                transform: "translateX(0)",
                transition: transitions.create("transform", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                }),

                [breakpoints.up("xl")]: {
                    width: sidebarWidth,
                    transform: "translateX(0)",
                    transition: transitions.create(
                        ["width", "background-color"],
                        {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.enteringScreen,
                        }
                    ),
                },
            },

            sidenav_close: {
                transform: `translateX(${pxToRem(-320)})`,
                transition: transitions.create("transform", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                }),

                [breakpoints.up("xl")]: {
                    width: pxToRem(80),
                    overflowX: "hidden",
                    transform: "translateX(0)",
                    transition: transitions.create(
                        ["width", "background-color"],
                        {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.shorter,
                        }
                    ),
                },
            },

            sidenav_navlink: {
                textDecoration: "none",
            },

            sidenav_tymelineLogo: {
                maxWidth: "auto",
                maxHeight: "35px",
                objectFit: "cover",
                transition: transitions.create("transform", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                }),
            },
        };
    }
);
