import React from "react";
import { useSelector } from "react-redux";
import { Navbar, Nav, NavItem } from "components/toolkit";
import { userSelector } from "selectors/user";

const Footer = () => {
    const state = useSelector(userSelector);

    return (
        <div className='rookie-footer'>
            {/* <Navbar>
        <Nav navbar>
          <NavItem>
            <a
              style={{
                color:
                  state !== null
                    ? state.primary !== undefined
                      ? state.primary
                      : "#38B6FF"
                    : "#38B6FF",
              }}
              href="https://tymeline.id/"
              // href="https://www.kwickly.app/"
              target="blank"
            >
              Powered by tymeline
            </a>
          </NavItem>
        </Nav>
      </Navbar> */}
        </div>
    );
};

export default Footer;
