/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";
// The Soft UI Dashboard React main context
const SoftUI = createContext();

// Soft UI Dashboard React reducer
function reducer(state, action) {
    switch (action.type) {
        case "APP_TITLE": {
            return { ...state, appTitle: action.value };
        }
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "PIN_SIDENAV": {
            return { ...state, pinSidenav: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

// Soft UI Dashboard React context provider
function SoftUIControllerProvider({ children }) {
    const initialState = {
        appTitle: { title: "Tymeline App" },
        miniSidenav: false,
        pinSidenav: true,
        transparentSidenav: false,
        sidenavColor: "info",
        transparentNavbar: true,
        fixedNavbar: true,
        openConfigurator: false,
        direction: "ltr",
        layout: "dashboard",
    };

    const [controller, dispatch] = useReducer(reducer, initialState);

    return (
        <SoftUI.Provider value={[controller, dispatch]}>
            {children}
        </SoftUI.Provider>
    );
}

// Soft UI Dashboard React custom hook for using context
function useSoftUIController() {
    return useContext(SoftUI);
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
const setOpenConfigurator = (dispatch, value) =>
    dispatch({ type: "OPEN_CONFIGURATOR", value });

export { SoftUIControllerProvider, setOpenConfigurator, useSoftUIController };
