import React from "react";
import { Tooltip, Box } from "components/toolkit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyContentComponent = ({ content, msg, color, styleProps }) => {
    const [copiedStatus, setCopiedStatus] = React.useState(false);
    const handleCopyCode = () => {
        setCopiedStatus(true);
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        textarea.value = content;
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(textarea);
    };
    return (
        <Tooltip title={copiedStatus ? "Copied!" : msg} placement='top'>
            <Box
                display='inline-flex'
                alignItems='center'
                onMouseLeave={() => {
                    setCopiedStatus(false);
                }}
                onClick={handleCopyCode}
                sx={{
                    ml: 1,
                    ...styleProps,
                    cursor: "pointer",
                }}>
                <ContentCopyIcon
                    color={color ? color : "primary"}
                    fontSize='small'
                    sx={{ cursor: "pointer" }}
                />
            </Box>
        </Tooltip>
    );
};

export default CopyContentComponent;
