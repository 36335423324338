/* eslint-disable no-template-curly-in-string */

export const TextLabels = {
    company: {
        create: {
            title: "Company details has been added successfully.",
            subtitle: "To experience new theme colors please login again.",
        },
        update: {
            title: "Company details has been updated successfully.",
            subtitle: "To experience new theme colors please login again.",
        },
    },
    logout: {
        label: "Are you sure you want to log out?",
    },
    sidebar: {
        dashboard: "Dashboard",
        company: "Company Details",
        role: "Roles",
        user: "Users",
        reports: "Reports",
        workflow: "Workflow",
    },
    subheader: {
        orientation: "Orientation",
        training: "Trainings",
        issue_letter: "Issue Letters",
        paperbook: "Paperbook",
        company_policy: "Company Policies",
        compensation: "Compensation & Benefits",
        offboarding: "Offboarding",
        workflow: "workflow",
        create: "Create Submenu",
        // create: "Categories",
        dateTime: " Date Time Details",
        action: " Action Details",
        meeting: "Meeting Details",
    },
    header: {
        company: {
            add: "Add Company Details",
            edit: "Edit Company Details",
            view: "Company Details",
        },
        onboard: {
            label: "Candidate Details",
            candidate: "Onboard Candidate",
            view: "Dashboard",
        },
        role: {
            label: "Roles",
        },
        orientation: {
            add: "Add Orientation",
            edit: "Edit Orientation",
            question: "Add Question",
            view: "Orientation",
        },
        training: {
            add: "Add Training",
            edit: "Edit Training",
            view: "Trainings",
        },
        user: {
            add: "Add User",
            edit: "Edit User Details",
            view: "Users",
        },
        issue: {
            label: "Issue Letters",
        },
        compensation: {
            label: "Compensation & Benefits",
        },
        company_policy: {
            label: "Company Policies",
        },
        workflow: {
            label: "Workflow",
        },
        teams: {
            label: "Teams",
        },
    },
    delete: {
        candidate: "Are you sure you want to delete this Candidate?",
        role: "Are you sure you want to delete Role?",
        question: "Are you sure you want to delete this Question?",
        orientation: "Are you sure you want to delete this Orientation?",
        training: "Are you sure you want to delete this Training?",
        workflow: "Are you sure you want to delete this Workflow?",
        compensation: "Are you sure you want to delete this Compensation?",
        company: "Are you sure you want to delete this Company policy?",
        team: "Are you sure you want to delete the team?",
    },
    password: {
        forgot: {
            title: "Forgot Password?",
            subtitle: "You can reset your password here.",
        },
        thank: {
            title: "Thank You!",
            label: "Please check your email",
            subtitle:
                " for further instructions on how to reset your password.",
        },
        reset: {
            label: "Reset Password",
            title: "Enter your new password here.",
            subtitle:
                "Your Password updated successfully. Please click on the link below to login with your new password.",
        },
    },
    clickToCopy: {
        note: {
            label: "Note",
            message: "Click to copy the variables",
        },
        candidateName: {
            value: "${first_name}",
            label: "Candidate name",
            message: "Place it in the Candidate's name",
        },
        role: {
            value: "${role}",
            label: "Role title",
            message: "Place it in the Role's title",
        },
        companyName: {
            value: "${company_name}",
            label: "Company name",
            message: "Place it in the Company's name",
        },
        compensation: {
            value: "${compensation}",
            label: "Candidate's compensation",
            message: "Place it in the compensation",
        },
        sign: {
            value: "${sign_name}",
            label: "Signatory Name",
            message: "Place it in the Signatory Name",
        },
        signature: {
            value: "${sign}",
            label: "Signature",
            message: "Place it in the Signature",
        },
    },
    onboard: {
        individual: {
            email: {
                label: "Email",
            },
            role: {
                label: "Role",
            },
            gender: {
                label: "Gender",
            },
            dob: {
                label: "Date of Birth",
            },
            compensation: {
                label: "Compensation",
            },
            reportees: {
                label: "Reporting to",
            },
            expiry: {
                label: "Link expires in",
            },
            score: {
                label: "Completion score",
            },
            details: {
                label: "Onboard Details",
            },
            url: {
                label: "Employee onboarding link",
                link: "Click to copy link",
            },
            personal: {
                label: "Personal Details",
            },
            phone: {
                label: "Phone",
            },
            dateOfBirth: {
                label: "Date of birth",
            },
            alternate: {
                label: "Alternate phone",
            },
            address: {
                label: "Address",
            },
            aadhar: {
                label: "Aadhar number",
            },
            pan: {
                label: "Pan number",
            },
            passport: {
                label: "Passport number",
            },
            education: {
                label: "Education Details",
            },
            university: {
                label: "University / Board",
            },
            school: {
                label: "School / College",
            },
            course: {
                label: "Course",
            },
            year: {
                label: "Pass out year",
            },
            description: {
                label: "Description",
            },
            work: {
                label: "Work Details",
            },
            company: {
                label: "Company",
            },
            designation: {
                label: "Designation",
            },
            start: {
                label: "Start date",
            },
            end: {
                label: "End date",
            },
            accept: {
                document: "Document",
                message:
                    "The above document has been read and accepted by the candidate.",
            },
        },
        form: {
            title: "Candidate has been created successfully.",
        },
        dateTime: {
            subject: "Subject",
            dateTime: "Date And Time",
        },
        meeting: {
            subject: "Subject",
            meetingDetails: "Meeting Details",
            additionalDetails: "Additional Details",
            menuType: "Menu Type",
        },
        action: {
            subject: "Subject",
            email: "Email",
            phone: "Phone No.",
            description: "Description",
        },
    },
    orientation: {
        question: {
            label: "Question",
        },
        answer: {
            label: "Answer",
        },
        options: {
            label: "Options",
        },
        option1: {
            label: "1. ",
        },
        option2: {
            label: "2. ",
        },
        option3: {
            label: "3. ",
        },
        option4: {
            label: "4. ",
        },
        edit: {
            error: {
                label: "There is some server issue. Kindly check after some time.",
            },
        },
    },
    offer: {
        title: "Offer Letter",
    },
    copy: "Copied",
    modal: {
        personal: {
            title: "Candidate's personal details has been updated successfully.",
        },
        education: {
            title: "Candidate's education details has been updated successfully.",
        },
        work: {
            title: "Candidate's work details has been updated successfully.",
        },
        issue: {
            add: "Issue letter has been added successfully.",
            edit: "Issue letter has been updated successfully.",
        },
        company_policy: {
            add: "Company Policy has been added successfully.",
            edit: "Company Policy has been updated successfully.",
        },
        compensation: {
            add: "Compensation & Benefits has been added successfully.",
            edit: "Compensation & Benefits has been updated successfully.",
        },
    },
    display: {
        training: {
            title: "Training title",
            question: "Question",
            answer: "Required answer",
            candidate: "Candidate's answer",
            score: "Score",
        },
        orientation: {
            title: "Orientation title",
            question: "Question",
            answer: "Required answer",
            candidate: "Candidate's answer",
            no_question: "No questions to display.",
        },
    },
    alertDisplay: {
        training: {
            title: "Please fill all the fields correctly and check if you have selected the answers. Given minimum of 2 questions.",
            subtitle:
                "Please fill all the fields correctly and check if you have selected the answers.",
        },
        required: "Please fill all the required fields.",
        role: "Select atleast one role",
        logo: "Invalide file dimensions. Give 100 X 100",
        file: "Invalide file size. Upload file below or equal to 5mb.",
    },
    dashboard: {
        inprogress: "Hiring Inprogress",
        completed: "Completed",
        selected: "Selected",
        onboarding: "Onboarding",
        offboarding: "Offboarding",
    },
    plot: {
        overview: "OverViewPlot",
        gender: "genderPlot",
        setOn: "SetoOnPlot",
        comtose: "ComtoSePlot",
        intocom: "IntoComPlot",
        skill: "SkillsPlot",
        role: "RolesPlot",
        age: "AgePlot",
    },
};
