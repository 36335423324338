// src/components/TeamTabs.js
import React from "react";
import { Tabs as MuiTabs, Tab } from "@mui/material";
import { getTabStyles, tabsStyles, tabIndicatorProps } from "./tabStyles";

const Tabs = ({ value, tabItems = [], onChange }) => {
    return (
        <MuiTabs
            value={value}
            onChange={(_event, value) => onChange(value)}
            TabIndicatorProps={tabIndicatorProps}
            sx={tabsStyles}>
            {tabItems.map((item, index) => (
                <Tab
                    key={index}
                    icon={item.icon}
                    label={item.label}
                    sx={getTabStyles(value === index)}
                />
            ))}
        </MuiTabs>
    );
};

export default Tabs;
