import React from "react";

const ProjectAnalysisPage = React.lazy(
    () => import("pages/project/ProjectAnalysisPage")
);
const TymelineVerifiyRequestComponent = React.lazy(
    () => import("pages/Tymeline/TymelineVerifiyRequestComponent")
);
const PeopleSearchResultsPage = React.lazy(
    () => import("pages/peopleSearch/PeopleSearchResultsPage")
);
const ViewEpicPage = React.lazy(() => import("pages/epics/ViewEpic"));
const EditTeamPage = React.lazy(() => import("pages/teams/EditTeamPage"));
const EditTeamGoalPage = React.lazy(() => import("pages/Goals/EditTeamGoal"));
const EditOrganizationGoalPage = React.lazy(
    () => import("pages/Organizational Goals/EditOrganizationGoal")
);
const StoriesList = React.lazy(() => import("pages/epics/StoriesList"));
const IndividualTeamGoalPage = React.lazy(
    () => import("pages/Goals/IndividualTeamGoal")
);

const IndividualTeamMemberGoalPage = React.lazy(
    () => import("pages/TeamMemberGoals/IndividualTeamMemberGoal")
);

const CreateTeamPage = React.lazy(() => import("pages/teams/CreateTeamPage"));
const IndividualTeamPage = React.lazy(
    () =>
        import(
            "components/required-components/team-component/IndividualTeamPage"
        )
);
const CreateTeamGoalPage = React.lazy(() => import("pages/Goals/CreateGoal"));
const CreateNewTeamGoalPage = React.lazy(
    () => import("pages/Goals/CreateNewGoal")
);
// const CreateTeamMemberGoalPage = React.lazy(() => import("pages/Goals/CreateGoal"));

const CreateOrganizationGoalPage = React.lazy(
    () => import("pages/Organizational Goals/CreateGoal")
);

const CreateEpicPage = React.lazy(() => import("pages/epics/index"));

const CreateProjectPage = React.lazy(() => import("pages/project/create"));

const ViewProjectPage = React.lazy(() => import("pages/project/index"));
const ViewProjectPageNew = React.lazy(() => import("pages/project/indexNew"));

const TalentDashboardPage = React.lazy(
    () => import("pages/talent/DashboardNew")
);

const CreateTeamMemberGoalPage = React.lazy(
    () => import("pages/TeamMemberGoals/CreateGoal")
);
// const CreateProjectPage = React.lazy(() =>
//     import("pages/projects/CreateProjectPage")
// );

const EditTemplatePage = React.lazy(
    () => import("pages/projects/templates/EditTemplatePage")
);

const CreateTemplatePage = React.lazy(
    () => import("pages/projects/templates/CreateTemplatePage")
);

const TymelineOverallview = React.lazy(
    () => import("pages/Tymeline/TymelineOverallview")
);
const ViewTymeline = React.lazy(
    () => import("pages/Tymeline/TymelineComponent")
);
const TymelineOtpVerifyComponent = React.lazy(
    () => import("pages/Tymeline/TymelineOtpVerifyComponent")
);
const TymelineValidateComponent = React.lazy(
    () => import("pages/Tymeline/TymelineValidateComponent")
);
const NominateCandidatePage = React.lazy(
    () => import("pages/NominateCandidate")
);
const TeamGoalsPage = React.lazy(() => import("pages/Goals/DynamicTeamGoals"));

const UserInvitePage = React.lazy(() => import("pages/userInvite"));
const CreateUserPage = React.lazy(
    () => import("pages/userInvite/CreateUserPage")
);

const UserInfoPage = React.lazy(() => import("pages/UserInfo"));

const routes = [
    // {
    //   path: "/vacancies",
    //   name: "Vacancies",
    //   type: "collapse",
    //   component: VacancyPage,
    //   icon: <WorkIcon size="12px" />,
    //   exact: true,
    //   showInMenu: true,
    // },
    {
        path: "/view-tymeline/:consentId",
        type: "collapse",
        component: ViewTymeline,
        exact: true,
        role: "verificationHub",
    },
    {
        path: "/verify-tymeline",
        type: "collapse",
        component: TymelineOtpVerifyComponent,
        exact: true,
        role: "verificationHub",
    },
    {
        path: "/tymeline/validate-event/:id",
        type: "collapse",
        component: TymelineValidateComponent,
        exact: true,
        role: "verificationHub",
    },
    {
        path: "/tymeline/create-nomination",
        type: "collapse",
        component: NominateCandidatePage,
        exact: true,
        role: "verificationHub",
    },
    {
        path: "/tymeline/overall-tymeline-view/",
        type: "collapse",
        component: TymelineOverallview,
        exact: true,
        role: "verificationHub",
    },

    // Route for external link
    {
        path: "/tymeline/company-verification-request/:bid/:token",
        type: "collapse",
        component: TymelineVerifiyRequestComponent,
        exact: true,
        role: "verificationHub",
    },
    {
        path: "/talent/talent-search-results",
        type: "collapse",
        component: PeopleSearchResultsPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/talent/user-info/:consentId",
        type: "collapse",
        component: UserInfoPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/talent/dashboard/new",
        type: "collapse",
        component: TalentDashboardPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/create-project",
        type: "collapse",
        component: CreateProjectPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/create-project/:projectId",
        type: "collapse",
        component: CreateProjectPage,
        exact: true,
        role: "peopleHub",
    },
    // {
    //     path: "/projects/create-project",
    //     type: "collapse",
    //     component: CreateProjectPage,
    //     exact: true,
    //     role: "peopleHub",
    // },
    {
        path: "/projects/view/:projectId",
        type: "collapse",
        component: ViewProjectPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/view/:projectId/new",
        type: "collapse",
        component: ViewProjectPageNew,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/edit-template/:id",
        type: "collapse",
        component: EditTemplatePage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/create-template",
        type: "collapse",
        component: CreateTemplatePage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/talent/edit-team/:teamId",
        type: "collapse",
        component: EditTeamPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/team/:title/:id",
        type: "collapse",
        component: IndividualTeamPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/edit-user-story/:teamGoalId",
        type: "collapse",
        component: EditTeamGoalPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/user-story/:teamGoalId",
        type: "collapse",
        component: IndividualTeamGoalPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/milestone/:teamMemberGoalId",
        type: "collapse",
        component: IndividualTeamMemberGoalPage,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/talent/create-team",
        type: "collapse",
        component: CreateTeamPage,
        exact: true,
        role: "peopleHub",
    },
    // {
    //     path: "/projects/create-epic-old",
    //     type: "collapse",
    //     component: CreateOrganizationGoalPage,
    //     exact: true,
    // },
    {
        path: "/projects/epic/new/",
        type: "collapse",
        component: CreateEpicPage,
        exact: true,
        strict: true,
    },
    {
        path: "/projects/:projectId/analysis",
        type: "collapse",
        component: ProjectAnalysisPage,
        exact: true,
        strict: true,
    },
    // {
    //     path: "/projects/create-project",
    //     type: "collapse",
    //     component: CreateProjectPage,
    //     exact: true,
    // },
    // {
    //     path: "/projects/epic/:organizationGoalId",
    //     type: "collapse",
    //     component: EditOrganizationGoalPage,
    //     exact: true,
    //     role: "peopleHub",
    // },
    {
        path: "/projects/epic/:epicId",
        type: "collapse",
        component: ViewEpicPage,
        exact: true,
        strict: true,
        role: "peopleHub",
    },
    {
        path: "/projects/epic/:epicId/stories",
        type: "collapse",
        component: StoriesList,
        exact: true,
        role: "peopleHub",
    },
    {
        path: "/projects/create-team-goal/:organizationGoalId",
        type: "collapse",
        component: CreateTeamGoalPage,
        exact: true,
    },
    {
        path: "/projects/create-new-team-goal",
        type: "collapse",
        component: CreateNewTeamGoalPage,
        exact: true,
    },
    {
        path: "/projects/create-team-member-goal/:teamGoalId/:teamMemberGoalId/:teamMemberId",
        type: "collapse",
        component: CreateTeamMemberGoalPage,
        exact: true,
    },
    {
        path: "/projects/team-goals/:goalId",
        type: "collapse",
        component: TeamGoalsPage,
        exact: true,
    },
    {
        path: "/acceptuserinvite/:inviteToken",
        type: "collapse",
        component: UserInvitePage,
        exact: true,
    },
    {
        path: "/create-new-user",
        type: "collapse",
        component: CreateUserPage,
        exact: true,
    },
];

export default routes;
