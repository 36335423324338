export const setDashboardData = (payload) => ({
  type: "SET_DASHBOARD_DATA",
  payload,
});

export const setGrowthOverviewData = (payload) => ({
  type: "SET_GROWTH_OVERVIEW",
  payload,
});

export const setTymelineScoreData = (payload) => ({
  type: "SET_TYMELINE_SCORE",
  payload,
});

export const setSkillsOverviewData = (payload) => ({
  type: "SET_SKILLS_OVERVIEW",
  payload,
});

export const setGrowthRecommendationData = (payload) => ({
  type: "SET_GROWTH_RECOMMENDATION_DATA",
  payload,
});

export const setSurveysListData = (payload) => ({
  type: "SET_SURVEYS_LIST_DATA",
  payload,
});

export const setSurveyButton = (payload) => ({
  type: "SHOW_SURVEY_BUTTON",
  payload,
});

export const setAdminDashboardData = (payload) => ({
  type: "SET_ADMIN_DASHBOARD_DATA",
  payload,
});

