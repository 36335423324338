/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useCallback, useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// clsx is a utility for constructing className strings conditionally
import clsx from "clsx";

// @mui material components
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

// Soft UI Dashboard React components
import { Box, Typography } from "components/toolkit";

// Soft UI Dashboard React example components
import SidenavCollapse from "components/toolkit/Sidenav/SidenavCollapse";
import SidenavItem from "components/toolkit/Sidenav/SidenavItem";
import SidenavList from "components/toolkit/Sidenav/SidenavList";
// import SidenavCard from "components/toolkit/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import styles from "components/toolkit/Sidenav/styles/sidenav";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

import WhiteLabelLogo from "assets/img/logo/TymelineLogo.png";
import { useSelector } from "react-redux";
import { selectCompanyName } from "selectors/company";
import { selectNewCompanyLogo } from "selectors/company";
import TymelineLogo from "assets/img/logo/tymeline.png";
// import SubmenuListConfig from "routes/SubmenuListConfig";
// import { MdLinearScale } from "react-icons/md";

import Joyride, { STATUS } from "react-joyride";
import SuiTypography from "../SuiTypography";
import { stepsData } from "./sideNavUtil";
import SidenavLock from "./SidenavLock";
import LockedSidenavItem from "./LockedSidenavItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function Sidenav({ routes, ...rest }) {
    // const dispachEvent = useDispatch();
    const [openCollapse, setOpenCollapse] = useState("Tymeline");
    // const [openCollapse, setOpenCollapse] = useState(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
    const [openDeepNestedCollapse, setOpenDeepNestedCollapse] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    // const accessPermissions = state?.access?.concat("default");
    const { miniSidenav, transparentSidenav, pinSidenav } = controller;
    const classes = styles({ miniSidenav, transparentSidenav });
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const itemName = pathname.split("/").slice(1)[1];
    const itemNestedName = pathname.split("/").slice(1)[2];
    // const itemIDName = pathname.split("/").slice(1)[4];
    const currentUser = useSelector((state) => state.user.currentUser);
    const accountDetails = useSelector((state) => state.dbUser.account_details);
    // const { trainingMenu, issueLetters, compensations } = SubmenuListConfig();
    const [tourState, setTourState] = useState({
        run: false,
        steps: stepsData,
    });

    // console.log(currentUser);

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatus = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatus.includes(status)) {
            // setTourState((prevValues) => ({ ...prevValues, run: false }));
            // localStorage.setItem("tour", JSON.stringify({ dashboard: true }));
            const storedTourValue = JSON.parse(localStorage.getItem("tour"));
            const currentTourValue = { ...storedTourValue, dashboard: true };
            localStorage.setItem("tour", JSON.stringify(currentTourValue));
        }
    };

    useEffect(() => {
        const getTourValue = localStorage.getItem("tour");
        const parsedTourValue = JSON.parse(getTourValue);
        if (parsedTourValue?.dashboard !== true) {
            setTourState((prevValues) => ({ ...prevValues, run: true }));
        }
    }, []);

    const closeSizenav = () => dispatch({ type: "MINI_SIDENAV", value: true });
    function handlePinSidenav() {
        if (!pinSidenav) {
            dispatch({ type: "MINI_SIDENAV", value: false });
            dispatch({ type: "PIN_SIDENAV", value: true });
        } else {
            dispatch({ type: "MINI_SIDENAV", value: true });
            dispatch({ type: "PIN_SIDENAV", value: false });
        }
    }

    // Render nested sub-routes inside of Sidenav
    const renderNestedCollapse = (collapse) => {
        const template = collapse.map(
            ({
                name,
                path,
                key,
                href,
                submenu,
                icon,
                role,

                // trainingSubmenu,
                // issueLetterSubmenu,
                // compensationSubmenu,
                to,
            }) => {
                let returnValue;

                if (submenu) {
                    // const list =
                    //   key === "trainings"
                    //     ? trainingMenu
                    //     : key === "compensations"
                    //     ? compensations
                    //     : issueLetters;
                    returnValue = (
                        <SidenavItem
                            key={key}
                            name={name}
                            role={role}
                            open={openDeepNestedCollapse === name}
                            icon={icon}
                            active={itemNestedName === key}
                            onClick={() =>
                                openDeepNestedCollapse === name
                                    ? setOpenDeepNestedCollapse(false)
                                    : setOpenDeepNestedCollapse(name)
                            }>
                            {/* {list?.sub_menu?.map((submenu, index) => (
                <NavLink
                  to={
                    !submenu.create
                      ? `${to}${submenu.sub_menu_id}`
                      : `${submenu.to}`
                  }
                  key={index}
                >
                  <SidenavItem
                    name={submenu.title}
                    nested
                    active={submenu.sub_menu_id === itemIDName}
                    icon={<MdLinearScale />}
                  />
                </NavLink>
              ))} */}
                        </SidenavItem>
                    );
                } else {
                    returnValue = href ? (
                        <Link
                            key={key}
                            href={href}
                            target='_blank'
                            rel='noreferrer'
                            sx={{ textDecoration: "none" }}>
                            <SidenavItem name={name} nested />
                        </Link>
                    ) : (
                        <NavLink
                            to={path}
                            key={key}
                            sx={{ textDecoration: "none" }}>
                            <SidenavItem
                                name={name}
                                active={path === pathname}
                                icon={icon}
                                nested
                            />
                        </NavLink>
                    );
                }
                return returnValue;
            }
        );

        return template;
    };

    // Render sub-routes inside of Sidenav

    const renderCollapse = (collapses) =>
        collapses.map(
            ({
                name,
                key,
                path,
                href,
                collapse,
                icon,
                validatorOnly,
                adderOnly,
                className,
                role,
            }) => {
                let returnValue;
                if (validatorOnly && !accountDetails?.is_validator) {
                    return null;
                }
                if (adderOnly && !accountDetails?.is_adder) {
                    return null;
                }
                if (collapse) {
                    returnValue = (
                        <SidenavItem
                            key={key}
                            name={name}
                            active={key === itemName}
                            icon={icon}
                            open={openNestedCollapse === name}
                            onClick={() =>
                                openNestedCollapse === name
                                    ? setOpenNestedCollapse(false)
                                    : setOpenNestedCollapse(name)
                            }>
                            {renderNestedCollapse(collapse)}
                        </SidenavItem>
                    );
                } else {
                    returnValue = href ? (
                        <Link
                            href={href}
                            key={key}
                            target='_blank'
                            rel='noreferrer'
                            sx={{ textDecoration: "none" }}>
                            <SidenavItem
                                name={name}
                                role={role}
                                active={key === itemName}
                                icon={icon}
                            />
                        </Link>
                    ) : (
                        <React.Fragment key={key}>
                            {role.includes(
                                currentUser.dashboardRoleType?.[0]
                            ) ? (
                                <NavLink
                                    to={path}
                                    sx={{ textDecoration: "none" }}>
                                    {/* here need to check adder and validator */}
                                    <SidenavItem
                                        className={className}
                                        name={name}
                                        role={role}
                                        active={key === itemName}
                                        icon={icon}
                                        nested
                                    />
                                </NavLink>
                            ) : (
                                <LockedSidenavItem
                                    className={className}
                                    name={name}
                                    active={key === itemName}
                                    icon={icon}
                                    nested
                                />
                            )}
                        </React.Fragment>
                    );
                }
                return <SidenavList key={key}>{returnValue}</SidenavList>;
            }
        );

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        (
            {
                type,
                name,
                icon,
                title,
                noCollapse,
                path,
                href,
                noSuperAdmin,
                collapse,
                className,
                keyName,
                accessKey,
                role,
            },
            index
        ) => {
            if (!currentUser?.is_superuser && noSuperAdmin === false) {
                return null;
            }
            let returnValue;
            // const key = path ? path.split("/").slice(1)[0] : title;
            const key = path ? path.split("/").slice(1)[0] : name;
            if (type === "collapse") {
                returnValue = href ? (
                    <Link
                        href={href}
                        key={key}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.sidenav_navlink}>
                        <SidenavCollapse
                            name={name}
                            icon={icon}
                            active={key === collapseName || !collapseName}
                            noCollapse={noCollapse}
                            className={className}
                        />
                    </Link>
                ) : path ? (
                    <React.Fragment key={key}>
                        {/* Adding the lock component if it is not admin  */}
                        {role.includes(currentUser.dashboardRoleType?.[0]) ? (
                            <NavLink
                                to={path}
                                className={classes.sidenav_navlink}>
                                <SidenavCollapse
                                    name={name}
                                    icon={icon}
                                    active={key === collapseName}
                                    noCollapse={noCollapse}
                                    className={className}
                                />
                            </NavLink>
                        ) : (
                            <SidenavLock
                                key={keyName}
                                className={className}
                                name={name}
                                icon={icon}
                                active={keyName === collapseName}
                                open={openCollapse === name}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    // <div className="tymeline">
                    <React.Fragment key={keyName}>
                        {role.includes(currentUser.dashboardRoleType?.[0]) ? (
                            <SidenavCollapse
                                className={className}
                                name={name}
                                icon={icon}
                                active={keyName === collapseName}
                                open={openCollapse === name}
                                onClick={() =>
                                    openCollapse === name
                                        ? setOpenCollapse(false)
                                        : setOpenCollapse(name)
                                }>
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        ) : (
                            <SidenavLock
                                key={keyName}
                                className={className}
                                name={name}
                                icon={icon}
                                active={keyName === collapseName}
                                open={openCollapse === name}
                            />
                        )}
                    </React.Fragment>

                    // </div>
                );
            } else if (type === "title") {
                returnValue = (
                    <Typography
                        key={index}
                        variant='caption'
                        fontWeight='bold'
                        textTransform='uppercase'
                        customClass={classes.sidenav_title}>
                        {title}
                    </Typography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key} />;
            }

            return returnValue;
        }
    );

    const companyName = useSelector(selectCompanyName);
    const companyLogo = useSelector(selectNewCompanyLogo);

    const handleSideToggle = useCallback(() => {
        dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
    }, [miniSidenav, dispatch]);

    return (
        <>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={tourState.run}
                scrollToFirstStep
                // showProgress
                showSkipButton={true}
                steps={tourState.steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                locale={{
                    skip: (
                        <SuiTypography variant='button' fontWeight='regular'>
                            Skip
                        </SuiTypography>
                    ),
                    back: (
                        <SuiTypography variant='button' fontWeight='regular'>
                            Back
                        </SuiTypography>
                    ),
                    next: (
                        <SuiTypography
                            variant='button'
                            fontWeight='regular'
                            textColor='white'>
                            Next
                        </SuiTypography>
                    ),
                    last: (
                        <SuiTypography
                            variant='button'
                            fontWeight='regular'
                            textColor='white'>
                            Last
                        </SuiTypography>
                    ),
                }}
            />
            <Drawer
                {...rest}
                variant='permanent'
                classes={{
                    paper: clsx(classes.sidenav, {
                        [classes.sidenav_open]: !miniSidenav,
                        [classes.sidenav_close]: miniSidenav,
                    }),
                }}>
                <Box customClass={classes.sidenav_header}>
                    <Box
                        display={{ xs: "block", xl: "none" }}
                        position='absolute'
                        top={0}
                        right={0}
                        p={1.625}
                        customClass='cursor-pointer'
                        onClick={closeSizenav}>
                        <Typography variant='h6' textColor='secondary'>
                            <Icon className='font-bold'>close</Icon>
                        </Typography>
                    </Box>
                    <NavLink to='/'>
                        <Box
                            component='img'
                            src={companyLogo ?? WhiteLabelLogo}
                            alt={companyName}
                            customClass={classes.sidenav_logo}></Box>
                        <Box customClass={classes.sidenav_logoLabel}>
                            <Typography
                                customClass={classes.sidenav_companyName}
                                component='h6'
                                textColor='2dark'
                                variant={
                                    companyName?.length > 15
                                        ? "subtitle3"
                                        : "subtitle2"
                                }
                                align='left'>
                                {companyName ?? ""}
                            </Typography>
                        </Box>
                    </NavLink>
                    {miniSidenav && (
                        <ChevronRightIcon
                            fontSize='small'
                            color='text'
                            onClick={handleSideToggle}
                            sx={{ cursor: "pointer" }}
                        />
                    )}
                    {!miniSidenav && (
                        <Box
                            customClass={classes.sidenav_pin}
                            display={{ xs: "none", xl: "flex" }}>
                            {/* {!pinSidenav ? (
                                <PushPinOutlinedIcon />
                            ) : (
                                <span
                                    class='material-symbols-outlined'
                                    style={{ fontSize: "16px" }}>
                                    keep_off
                                </span>
                            )} */}
                            {!miniSidenav && (
                                <ChevronLeftIcon
                                    fontSize='small'
                                    color='text'
                                    onClick={handleSideToggle}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Box customClass={classes.sidenav_divider} />
                <List sx={{ height: "100%" }}>{renderRoutes}</List>
                {/* <Box customClass={classes.sidenav_footer}></Box> */}
                <Box
                    mb={2}
                    display='flex'
                    pl={miniSidenav ? 0 : 2}
                    justifyContent={miniSidenav ? "center" : "flex-start"}>
                    <img
                        className={classes.sidenav_tymelineLogo}
                        src={miniSidenav ? WhiteLabelLogo : TymelineLogo}
                        alt='Powered by Tymeline'
                    />
                </Box>
            </Drawer>
        </>
    );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
