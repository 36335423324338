import { Box, Typography } from "components/toolkit";
import { getPageName } from "routes/authRoutesConfig";

const AppTitle = ({ appTitle, route }) => {
    return (
        <Box flex={1} display='flex' gap={1} alignItems='center'>
            <Typography
                sx={{ ml: 3 }}
                variant='h6'
                textColor='text'
                style={{
                    textTransform: "capitalize",
                }}>
                {appTitle?.title ?? getPageName(route) ?? "Tymeline App"}
            </Typography>
            {appTitle?.showBetaLabel && (
                <span className='badge-container-text'>Beta</span>
            )}
        </Box>
    );
};

export default AppTitle;
